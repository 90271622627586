import {
  Errors,
  defaultInternationalization,
  defaultLanguage,
  fallbackMainHreflang,
  hreflangVariationsFromMain,
} from 'corporate-types';
import {
  getCorrectLangs,
  internationalizationToLanguages,
  multiCompanySafeEnv,
} from 'corporate-utils';
import { LocalizedPath } from '../models/domain/type';
import { ParsedUrlQuery } from 'querystring';
import { Logger } from 'corporate-utils';

export const fromInternationalizationToHreflang = (
  internationalization: string,
  from?: string
): string | undefined => {
  if (!internationalization) {
    Logger.error(
      Errors.NO_INTERNATIONALIZATION_FOUND,
      internationalization,
      from
    );
    console?.trace?.();
  }

  return fallbackMainHreflang?.[internationalization] || undefined;
};

interface PaginationStructure {
  page: number;
  pageSize: number;
  totalItems: number;
}

export const getPaginationData = (
  sections: any[]
): PaginationStructure | undefined => {
  const content = (sections as any[]).find((section: any) => {
    return (
      section?.__component === 'category.content' ||
      section?.__component === 'news.news-archive'
    );
  });

  let pagination: PaginationStructure | undefined;
  switch (content?.__component) {
    case 'category.content':
      pagination = {
        page: Number(content?.pagination?.page),
        pageSize: Number(content?.pagination?.pageSize),
        totalItems: Number(content?.pagination?.totalItems),
      };
      break;
    case 'news.news-archive':
      pagination = {
        page: Number(content?.paginationNews?.page),
        pageSize: Number(content?.paginationNews?.pageSize),
        totalItems: Number(content?.paginationNews?.total),
      };
      break;
    default:
      break;
  }

  return pagination;
};

export const getPrevNextPaginationPageNumbers = (
  pagination: PaginationStructure
) => {
  let prevPageNumber: number | undefined;
  let nextPageNumber: number | undefined;
  const currentPageNumber = Number(pagination?.page);
  const pageSize = Number(pagination?.pageSize) ?? 0;
  const totalItems = Number(pagination?.totalItems) ?? 0;
  const lastPageNumber = Math.ceil(totalItems / pageSize);

  if (currentPageNumber && pageSize > 0 && totalItems > 0) {
    const isLastPage: boolean = currentPageNumber === lastPageNumber;
    const isOverLastPage: boolean = currentPageNumber > lastPageNumber;
    const isInvalidPageNumber: boolean = currentPageNumber < 1;
    const isFirstPage: boolean = currentPageNumber === 1;

    if (!isLastPage && !isOverLastPage && !isInvalidPageNumber) {
      nextPageNumber = currentPageNumber + 1;
    }
    if (!isFirstPage && !isOverLastPage && !isInvalidPageNumber) {
      prevPageNumber = currentPageNumber - 1;
    }
  }
  return {
    prevPageNumber,
    nextPageNumber,
  };
};

export const prevNextLinkGenerator = (
  pathWithoutQueryParams: string,
  query: any,
  pageNumber: number
): string | void => {
  const paramKeyValue: string[] = [];
  // copy every query params except the pagination param
  Object.keys(query)?.forEach((key: any) => {
    if (key !== 'slug' && key !== 'page') {
      paramKeyValue.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
      );
    }
  });

  // arbitrary add pagination number: this way we can add it even for the first page of the paginated pages, where "page" may not be defined since it's the default page
  paramKeyValue.push(
    `${encodeURIComponent('page')}=${encodeURIComponent(pageNumber)}`
  );

  const querySerialized = paramKeyValue.join('&');

  const link = `${
    process.env.NEXT_PUBLIC_URL?.endsWith?.('/')
      ? process.env.NEXT_PUBLIC_URL?.slice?.(0, -1)
      : process.env.NEXT_PUBLIC_URL
  }${pathWithoutQueryParams}?${querySerialized}`;

  const linkSanitized = link?.replace?.(/\/$/, '');
  return linkSanitized;
};

export const getHreflangVariationsFromMain = (
  mainHreflang: string
): string[] => {
  return hreflangVariationsFromMain?.[mainHreflang] || [];
};

export const canonicalGenerator = (
  locale: string,
  asPath: string,
  query: any
): string => {
  Logger.info('packages/corporate-ui/src/utils/seo.ts params', {
    query,
    asPath,
    locale,
  });

  const queryTranslated: any = {};

  ['page', 'pageSize'].forEach((key: string) => {
    if (query[key]) {
      queryTranslated[key] = query[key];
    }
  });

  let link = '';

  if (Object.keys(queryTranslated).length > 0) {
    const paramKeyValue = Object.keys(queryTranslated)?.map((key: any) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        queryTranslated[key]
      )}`;
    });

    const querySerialized = paramKeyValue.join('&');

    link = `${
      process.env.NEXT_PUBLIC_URL?.endsWith?.('/')
        ? process.env.NEXT_PUBLIC_URL?.slice?.(0, -1)
        : process.env.NEXT_PUBLIC_URL
    }${asPath?.split?.('?')?.[0]}?${querySerialized}`;
  } else {
    link = `${
      process.env.NEXT_PUBLIC_URL?.endsWith?.('/')
        ? process.env.NEXT_PUBLIC_URL.slice?.(0, -1)
        : process.env.NEXT_PUBLIC_URL
    }${asPath?.split?.('?')?.[0]}`;
  }

  if (link === '') {
    // Something went wrong
    link = `${process.env.NEXT_PUBLIC_URL}${
      process.env.NEXT_PUBLIC_URL?.endsWith?.('/') ? '' : '/'
    }${locale}`;
  }

  const linkSanitized = link?.replace?.(/\/$/, '');

  return linkSanitized;
};

export const hrefGenerator = (
  localizedPaths: any[],
  router: any,
  translations: any
) => {
  const alternates: { hrefLang: string; href: string }[] = [];

  localizedPaths
    ?.filter((localizedPath: LocalizedPath) => {
      const LANGS = getCorrectLangs(
        multiCompanySafeEnv({ key: 'NEXT_PUBLIC_ENV' }),
        process.env.NEXT_PUBLIC_COMPANY as string
      );
      if (
        localizedPath &&
        localizedPath.locale &&
        localizedPath.locale !== 'default'
      ) {
        return !!LANGS.find((lang) => {
          return lang === localizedPath?.locale;
        });
      }
    })
    ?.map?.((localizedPath: LocalizedPath) => {
      if (
        localizedPath &&
        localizedPath.locale &&
        localizedPath.locale !== 'default'
      ) {
        const mainHreflang = fromInternationalizationToHreflang(
          localizedPath?.locale,
          router?.asPath
        );

        const query = JSON.parse(JSON.stringify(router?.query)); // "Deep" copy
        delete query?.slug;

        if (mainHreflang) {
          const variations = getHreflangVariationsFromMain(mainHreflang);

          variations?.map((variation) => {
            const queryTranslated: any = {};

            const language =
              internationalizationToLanguages(
                variation || defaultInternationalization,
                'packages/corporate-ui/src/utils/seo.ts'
              ) || defaultLanguage;

            Object.keys(query).forEach((key: string) => {
              const translationFound = translations.keys.find(
                ({ keysTranslated }: { keysTranslated: string[] }) => {
                  return keysTranslated.indexOf(key) !== -1;
                }
              );

              if (
                translationFound &&
                translationFound.valueTranslated[language]
              ) {
                queryTranslated[translationFound.valueTranslated[language]] =
                  query[key];
              } else {
                if (['page', 'pageSize'].indexOf(key) !== -1) {
                  queryTranslated[key] = query[key];
                }
              }
            });

            let href = `/${mainHreflang}/${encodeURI(
              localizedPath?.href
            )}`?.replace?.(/\/$/, '');

            if (Object.keys(queryTranslated).length > 0) {
              const paramKeyValue = Object.keys(queryTranslated)?.map(
                (key: any) => {
                  return `${encodeURIComponent(key)}=${encodeURIComponent(
                    queryTranslated[key]
                  )}`;
                }
              );

              const querySerialized = paramKeyValue.join('&');

              href = `${href}?${querySerialized}`;
            }

            Logger.info({
              language,
              href: `${process.env.NEXT_PUBLIC_URL}${href}`,
            });

            alternates.push({
              hrefLang: variation,
              href: `${process.env.NEXT_PUBLIC_URL}${href}`,
            });
          });
        }
      }
    })
    ?.filter((noUndefined: any) => !!noUndefined);

  return alternates;
};

export const canonicalPropsGenerator = (
  query: ParsedUrlQuery,
  translations: any,
  locale?: string
) => {
  Logger.info(
    'packages/corporate-ui/src/utils/seo.ts canonicalPropsGenerator',
    query
  );

  let subcategory = '';

  const language =
    internationalizationToLanguages(
      locale || defaultInternationalization,
      'packages/corporate-ui/src/utils/seo.ts canonicalPropsGenerator'
    ) || defaultLanguage;

  Object.keys(query)?.forEach?.((key: string) => {
    const translationFound = translations?.keys?.find?.(
      ({ keysTranslated }: { keysTranslated: string[] }) => {
        return keysTranslated?.indexOf?.(key) !== -1;
      }
    );

    if (translationFound && translationFound?.valueTranslated?.[language]) {
      subcategory = translationFound?.valueTranslated?.[language];
    }
  });

  const url = `${locale ? `/${locale}` : ''}${
    query?.slug && (query?.slug as string[]).length > 0
      ? `/${(query?.slug as string[])?.join?.('/')}`
      : ''
  }${
    subcategory && query?.[subcategory]
      ? `?${subcategory}=${query[subcategory]}`
      : ''
  }`;

  return url;
};
