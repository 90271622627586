const fontConfig = {
  default: {
    light: {
      fontFamily: '\'IBM Plex Sans\', Arial, sans-serif',
      fontWeight: '300',
    },
    regular: {
      fontFamily: '\'IBM Plex Sans\', Arial, sans-serif',
      fontWeight: '400',
    },
    medium: {
      fontFamily: '\'IBM Plex Sans\', Arial, sans-serif',
      fontWeight: '500',
    },
    semiBold: {
      fontFamily: '\'IBM Plex Sans\', Arial, sans-serif',
      fontWeight: '600',
    },
    bold: {
      fontFamily: '\'IBM Plex Sans\', Arial, sans-serif',
      fontWeight: '700',
    },
    extraBold: {
      fontFamily: '\'IBM Plex Sans\', Arial, sans-serif',
      fontWeight: '800',
    },
  },
};

export const configureFonts = (
  config: { [key: string]: any } = {}
): { [key: string]: any } => {
  return { ...fontConfig, ...config };
};
