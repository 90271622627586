import { Errors, Languages } from 'corporate-types';
import { AbortController } from 'node-abort-controller';

const FORCE_H_CAPTCHA = false;

export async function verifyCaptchaToken(
  token: string,
  action: string,
  alternativeCaptcha: boolean
): Promise<boolean> {
  const hCaptchaSecreKey: string = process.env.H_CAPTCHA_SECRET_KEY || '';
  const googleRecaptchaSecreKey: string =
    process.env.GOOGLE_RECAPTCHA_SECRET_KEY || '';

  const controller = new AbortController();
  const signal: any = controller.signal;

  const resultCaptchaPromise = alternativeCaptcha
    ? await fetch('https://api.hcaptcha.com/siteverify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `secret=${hCaptchaSecreKey}&response=${token}`,
      signal,
    })
    : await fetch('https://www.google.com/recaptcha/api/siteverify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `secret=${googleRecaptchaSecreKey}&response=${token}`,
      signal,
    });

  setTimeout(() => controller.abort(), 5000);

  const resultCaptcha = await resultCaptchaPromise.json();

  if (
    (!alternativeCaptcha && // only reCaptcha invalidity conditions
      (resultCaptcha?.action !== action ||
        [
          'search',
          'contact',
          'workshopForm',
          'newsletter',
          'workWithUs',
          'downloadManual',
        ].indexOf(resultCaptcha.action) === -1 ||
        (resultCaptcha?.success === true && resultCaptcha?.score < 0.7))) ||
    !resultCaptcha?.success || // hCaptcha and reCaptcha invalidity conditions
    [
      'search',
      'contact',
      'workshopForm',
      'newsletter',
      'workWithUs',
      'downloadManual',
    ].indexOf(action) === -1
  ) {
    throw new Error(Errors.CAPTCHA_NOT_VALIDATED);
  } else {
    return true;
  }
}

export function getUseAlternativeCaptcha(userCountry: string): boolean {
  return (
    [
      Languages.CHINESE.toUpperCase(),
      Languages.CHINESE_SIMPLIFIED.toUpperCase(),
    ].includes(userCountry) || FORCE_H_CAPTCHA
  );
}

export async function callApiToVerifyCaptcha(
  token: string,
  alternativeCaptcha: boolean,
  locale?: string
) {
  const controller = new AbortController();
  const signal: any = controller.signal;
  const headers = locale ? { 'x-locale': locale } : undefined;
  const response = await fetch(
    `/api/captcha?token=${token}&action=search&alternativeCaptcha=${alternativeCaptcha}`,
    { signal, headers }
  );
  setTimeout(() => controller.abort(), 5000);

  return response;
}
