import React, { useEffect, useReducer } from 'react';
import { createTheme, ThemeProvider as ThemeProviderMUI } from '@mui/material';
import { ThemeContext } from '@emotion/react';
import { mergeObjects } from 'corporate-utils';

import { themeReducer } from './reducers';
import { initialState } from './store';
import { getCompany } from '../utils/company';
import { Companies } from '../models/domain/enum';

declare module '@mui/material/styles' {
  interface Theme {
    [field: string]: any;
  }
}
export const ThemeProvider: React.FC<{
  children: React.ReactNode;
}> = (
  props: Partial<{
    children: React.ReactNode;
  }>
) => {
  const [state, dispatch] = useReducer(themeReducer, initialState);
  const values = { state, dispatch };
  const { theme } = state;

  let font = '';

  switch (getCompany()) {
    default:
    case Companies.SIRMAN:
      font = ['IBM Plex Sans'].join(',');
      break;

    case Companies.FELSINEA:
      font = ['Public Sans'].join(',');
      break;
  }

  const themeMUI = createTheme({
    typography: {
      fontFamily: font,
    },
  });

  useEffect(() => {
    dispatch({
      type: 'CHANGE_THEME',
      company: getCompany() || Companies.SIRMAN,
    });
  }, []);

  return (
    <ThemeContext.Provider value={values}>
      <ThemeProviderMUI theme={mergeObjects(theme, themeMUI)}>
        {props.children}
      </ThemeProviderMUI>
    </ThemeContext.Provider>
  );
};
