import { Action, HistoryActions, HistoryState } from './models/interface';
import { initialState } from './store';

export const historyReducer = (
  state: HistoryState = initialState,
  action: Action
): HistoryState => {
  const { type, url, page } = action;

  switch (type) {
    case HistoryActions.SET: {
      return { ...state, url: url, page: page };
    }
    case HistoryActions.RESET: {
      return { ...state, url: undefined, page: undefined };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
