import React, { useReducer } from 'react';

import { snackbarReducer } from './reducers';
import { initialState, snackbarContext as SnackbarContext } from './store';

export const SnackbarProvider: React.FC<{
  children: React.ReactNode;
}> = (
  props: Partial<{
    children: React.ReactNode;
  }>
) => {
  const [state, dispatch] = useReducer(snackbarReducer, initialState);
  const values = { state, dispatch };

  return (
    <SnackbarContext.Provider value={values}>
      {props.children}
    </SnackbarContext.Provider>
  );
};
