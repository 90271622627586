import React, { useReducer } from 'react';

import { historyReducer } from './reducers';
import { initialState, historyContext as HistoryContext } from './store';

export const HistoryProvider: React.FC<{
  children: React.ReactNode;
}> = (
  props: Partial<{
    children: React.ReactNode;
  }>
) => {
  const [state, dispatch] = useReducer(historyReducer, initialState);
  const values = { state, dispatch };

  return (
    <HistoryContext.Provider value={values}>
      {props.children}
    </HistoryContext.Provider>
  );
};
