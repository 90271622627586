import { Companies } from '../models/domain/enum';

export const getCompany = (): Companies => {
  if (process.env.NEXT_PUBLIC_COMPANY) {
    switch (process.env.NEXT_PUBLIC_COMPANY) {
      case Companies.SIRMAN:
        return Companies.SIRMAN;
      case Companies.FELSINEA:
        return Companies.FELSINEA;
    }
  }
  return Companies.SIRMAN;
};
