import { createContext } from 'react';

import { Companies } from '../models/domain/enum';
import { ThemeContext, ThemeState } from './models/interface';

import { sirman } from './themes/sirman';

export const initialState: ThemeState = {
  theme: sirman,
  company: Companies.SIRMAN,
};

export const themeContext = createContext<ThemeContext>({
  state: initialState,
  dispatch: () => {},
});
