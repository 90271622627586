import { mergeObjects } from 'corporate-utils';
import common from './default';
import { space, unit } from '../utils/units';

const override = {
  colors: {
    primary: '#05788F',
    primaryHover: '#035C6D',
    secondary: '#C0932A',

    activeDot: '#0797B0',
    mainTitleText: '#035C6D',
    primaryText: '#25231D',
    secondaryText: '#443F36',
    tertiaryText: '#5F584E',
    secondaryBackground: '#F6F5F3',
    divider: '#E2DFDA',
    border: '#E2DFDA',
    borderSecondary: '#C7C3BC',

    // Autocomplete
    autocompleteItemBackgroundHover: '#E3BA5A',

    // Workshop
    mainTitleWorkshopText: '#88EAFB',

    // Shell
    drawerCaretDown: '#969188',
  },

  megamenu: {
    heigthFixer: '140px',
    marginBottom: '100px',
    maxWidth: '1960px',
    boxShadow: '2px 20px 20px rgb(0 0 0 / 20%)',
  },

  // Borders
  borders: {
    unit: (molt = 1): string => `${space * 1 * molt}${unit}`,
    roundness: `1${unit}`,
    autocomplete: `4${unit}`,
  },

  fonts: {
    default: {
      thin: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '100',
      },
      extraLight: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '200',
      },
      light: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '300',
      },
      regular: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '400',
      },
      medium: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '500',
      },
      semiBold: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '600',
      },
      bold: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '700',
      },
      extraBold: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '800',
      },
      black: {
        fontFamily: '\'Public Sans\', Arial, sans-serif',
        fontWeight: '900',
      },
    },

    utility: {
      size: (molt = 1): string => `${space * 2 * molt}${unit}`,
      lineHeight: (molt = 1): string => `${space * 4 * molt}${unit}`,
    },

    secondaryButtonSpacing: '1.96px',
  },
};

export const felsinea = mergeObjects(common, override);
