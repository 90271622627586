import { AlertSeverity } from '../../../models/enum';

export interface SnackbarContext {
  state: SnackbarState;
  dispatch: (action: Action) => void;
}

export interface SnackbarState {
  show: boolean;
  message: string;
  alertSeverity: AlertSeverity;
}

export interface Action {
  type: string;
  success?: boolean;
  message?: string;
}

export enum SnackbarActions {
  SHOW = 'SHOW_SNACKBAR',
  HIDE = 'HIDE_SNACKBAR',
}
