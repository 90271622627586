// Put here the errors enums

export enum AmplifyErrors {
  NOT_AUTHORIZED = 'NotAuthorizedException',
}

export enum Errors {
  FORM_NOT_SUPPORTED = 'form-not-supported',
}

export enum TextSize {
  'small',
  'medium',
  'large',
  'contactForm',
  'contactFormModal',
  'workshop',
}

export enum ModalType {
  ALERT = 'alert',
  INITIAL = 'initial',
  PROMPT = 'prompt',
  FULLSCREEN = 'fullscreen',
}

export enum SelectType {
  'primary',
  'secondary',
  'third',
  'disabled',
}

export enum ModalStyleType {
  contactUs,
  reservedArea,
  localizations,
  mobileFilters,
}

export enum ModalSize {
  BIG = 'big',
  INITIAL = 'initial',
  SMALL = 'small',
  MEDIUM = 'medium',
  FULLSCREEN = 'FULLSCREEN',
}

export enum AlertSeverity {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum SocialType {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  WHATSAPP = 'whatsapp',
  TELEGRAM = 'telegram',
  LINKEDIN = 'linkedin',
  PINTEREST = 'pinterest',
  EMAIL = 'email',
  PRINT = 'print',
  INSTAGRAM = 'instagram',
}

export enum HeaderDescriptionPositionType {
  DEFAULT = 'default',
  UNDER = 'under',
  HIDDEN = 'hidden',
}

export enum HeaderCoverResizeType {
  DEFAULT_COVER = 'default_cover',
  CONTAIN = 'contain',
  HIDDEN = 'hidden',
}
