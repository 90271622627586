export interface HistoryContext {
  state: HistoryState;
  dispatch: (action: Action) => void;
}

export interface HistoryState {
  url?: string;
  page?: HistoryPage;
}

export interface Action {
  type: string;
  url?: string;
  page?: HistoryPage;
}

export enum HistoryPage {
  CATALOG = 'catalog',
}

export enum HistoryActions {
  SET = 'SET_HISTORY',
  RESET = 'RESET_HISTORY',
}
