import React from 'react';
import { LogoLaFelsinea, LogoSirman } from '../../assets';
import { Companies } from '../../models/domain/enum';

export const logoMapper = (): React.ReactElement => {
  if (process.env.NODE_ENV !== 'test') {
    switch (process.env.NEXT_PUBLIC_COMPANY) {
      case Companies.SIRMAN:
        return <LogoSirman />;
      case Companies.FELSINEA:
        return <LogoLaFelsinea />;
      default:
        return <LogoSirman />;
    }
  } else {
    return <LogoSirman />;
  }
};
