import { createContext } from 'react';

import { SnackbarContext, SnackbarState } from './models/interface';
import { AlertSeverity } from '../../models/enum';

export const initialState: SnackbarState = {
  show: false,
  message: '',
  alertSeverity: AlertSeverity.success,
};

export const snackbarContext = createContext<SnackbarContext>({
  state: initialState,
  dispatch: () => {},
});
