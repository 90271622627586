// Put here the generic domain enums

export enum Companies {
  SIRMAN = 'sirman',
  FELSINEA = 'felsinea',
}

export enum ProductAssetsType {
  PDF_MANUAL = 'pdf_manual',
  AUTOCAD_ICONS = 'autocad_icons',
  PDF_CATALOG = 'pdf',
}

export enum ImageViewerType {
  LIST = 'list',
  PREVIEW = 'preview',
}

export enum ImageRatio {
  SQUARE = 'square',
}

export enum ModalType {
  ALERT = 'alert',
  INITIAL = 'initial',
  PROMPT = 'prompt',
  FULLSCREEN = 'fullscreen',
}

export enum ModalSize {
  BIG = 'big',
  INITIAL = 'initial',
  SMALL = 'small',
  MEDIUM = 'medium',
  FULLSCREEN = 'FULLSCREEN',
}

export enum ImageType {
  BIG = 'big',
  SMALL = 'small',
  DETAIL = 'detail',
}

export enum ProductType {
  MODEL = '10',
  ACCESSORY = '20',
}

export enum AssetsType {
  INGOMBRI = 'ingombri',
  MANUALI = 'manuale',
  PDF = 'pdf',
  VIDEO = 'video',
}

export enum Pages {
  HOME = 'home',
  COMPANY = 'company',
  NEWS_EVENTS = 'news_events',
  NEWS_ARCHIVE = 'news_archive',
  EVENT = 'event',
  NEWS = 'news',
  CONTACTS = 'contacts',
  TERMS_CONDITIONS = 'terms_conditions',
  PRIVACY_POLICY = 'privacy_policy',
  SEARCH = 'search',
  CATEGORY = 'category',
  CATEGORY_FATHER = 'category_father',
  PRODUCT = 'product',
  ERROR_NOT_FOUND = 'error_not_found',
  ERROR_INTERNAL = 'error_internal',
  ERROR_GENERIC = 'error_generic',
  PROTECTED_AREA = 'protected_area',
  WORKSHOPS = 'workshops',
  WORKSHOP = 'workshop',
  CUSTOMER_CARE = 'customer_care',
  NO_TYPE = 'no_type',
  SECTOR = 'sector',
  EVENTS_ENTRY_PAGE = 'events',
  NEWS_ENTRY_PAGE = 'news_entry_page',
}

export enum ErrorTypes {
  NOT_FOUND = 'not-found',
  INTERNAL = 'internal',
}

export enum FormErrorMessageTypes {
  REQUIRED = 'required',
  ONE_OF = 'oneOf',
  EMAIL = 'email',
}

export enum MenuItemStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}
