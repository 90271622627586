import { Action, SnackbarActions, SnackbarState } from './models/interface';
import { initialState } from './store';
import { AlertSeverity } from '../../models/enum';

export const snackbarReducer = (
  state: SnackbarState = initialState,
  action: Action
): SnackbarState => {
  const { type, success = false, message = '' } = action;

  switch (type) {
    case SnackbarActions.SHOW: {
      const alertSeverity = success
        ? AlertSeverity.success
        : AlertSeverity.error;

      return { ...state, show: true, message, alertSeverity };
    }
    case SnackbarActions.HIDE: {
      return { ...state, show: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
