import { Action, ThemeState } from './models/interface';
import { sirman } from './themes/sirman';
import { felsinea } from './themes/felsinea';
import { initialState } from './store';
import { Companies } from '../models/domain/enum';

const themes = {
  sirman,
  felsinea,
};

export const themeReducer = (
  state: ThemeState = initialState,
  action: Action
): ThemeState => {
  const { type, company } = action;

  switch (type) {
    case 'CHANGE_THEME': {
      if (!Object.values(Companies).includes(company as Companies)) {
        throw new Error('Missing theme');
      }
      state.company = company;
      state.theme = themes[company as Companies];
      return { ...state, theme: themes[company as Companies] };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
