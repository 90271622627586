import {
  defaultInternationalization,
  defaultLanguage,
  fallbackMainHreflang,
} from 'packages/corporate-types';
import { internationalizationToLanguages } from 'packages/corporate-utils';
import { AssetsType, Companies, ImageType } from '../models/domain/enum';
import { ProductAsset } from '../models/domain/interfaces';
import { Logger } from 'corporate-utils';

export const getStrapiMedia = (url?: string | null): string => {
  if (typeof url !== 'string' || url == null || url.includes('/api/api/')) {
    return '';
  }

  if (url) {
    // Return the full URL if the media is hosted on an external provider
    if (
      typeof url.startsWith === 'function' &&
      (url.startsWith('http') || url.startsWith('//'))
    ) {
      return isValidImage(url);
    } else if (typeof url.startsWith !== 'function') {
      Logger.error(
        'packages/corporate-ui/src/utils/media.ts - url: ',
        url,
        typeof url.startsWith
      );
      console?.trace?.();
    }

    return isValidImage(url, (url: string) => `/api${url}`);
  } else {
    return '';
  }
};

export const getVideoEmbedId = (url: string): string => {
  const splittedUrl = url.split('=');
  if (splittedUrl.length > 1) {
    const splittedUrl2 = splittedUrl[1].split('&');
    if (splittedUrl.length > 0) {
      return splittedUrl2[0];
    }
  }
  return '';
};

export const getImages = (
  imagesRaw: any,
  locale: string
): string[] | undefined =>
  imagesRaw
    ?.filter(
      (image: any) =>
        image.language ===
        (
          internationalizationToLanguages(
            fallbackMainHreflang[locale] || defaultInternationalization,
            'packages/corporate-ui/src/utils/media.ts A'
          ) || defaultLanguage
        ).toUpperCase()
    )
    ?.filter(
      (image: any) =>
        image.type === ImageType.BIG || image.type === ImageType.DETAIL
    )
    ?.sort((image: any) => {
      if (image.type === ImageType.BIG) return -1;
      else return 1;
    })
    ?.map((image: any) => {
      const { file, type } = image;
      switch (type) {
        case ImageType.BIG:
          return `${process.env.IMAGE_URL_BASE_PATH_BIG}${file}`;
        case ImageType.SMALL:
          return `${process.env.IMAGE_URL_BASE_PATH_SMALL}${file}`;
        case ImageType.DETAIL:
          return `${process.env.IMAGE_URL_BASE_PATH_DETAIL_BIG}${file?.replace(
            'small',
            'big'
          )}`;
        default:
          return '';
      }
    })
    ?.filter((image: any) => !!image);

export const getCoverString = (imagesRaw: any): string => {
  const array: string[] = imagesRaw
    ?.filter((image: any) => image.type === ImageType.BIG)
    ?.map((image: any) => {
      const { file, language } = image;
      return `${language?.toUpperCase()}=${file}`;
    });

  return array?.join(';');
};

export const getVideo = (assets: ProductAsset[] | undefined) =>
  assets
    ?.filter(({ type }) => type === AssetsType.VIDEO)
    ?.map(({ value }) => {
      return `${value}`;
    })[0] || '';

export const getTechicalImages = (assets: ProductAsset[] | undefined) =>
  assets
    ?.filter(({ type }) => type === AssetsType.INGOMBRI)
    ?.map(({ value }) => {
      return `${process.env.IMAGE_URL_BASE_PATH_INGOMBRI}${value}`;
    });

export const getTechicalManual = (assets: ProductAsset[] | undefined) =>
  assets
    ?.filter(({ type }) => type === AssetsType.MANUALI)
    ?.map(({ value }) => {
      return `${process.env.MANUAL_URL_BASE_PATH}${value}`;
    })[0] || '';

export const isValidImage = (
  url: string,
  callback?: any,
  fallback = '/image/missing-image.webp'
): string => {
  let cb;

  if (!callback || typeof callback !== 'function') {
    cb = (url: string) => url;
  } else {
    cb = callback;
  }

  if (/\.(gif|jpe?g|tiff?|png|webp|bmp|ico)$/i.test(url)) {
    return cb(url || '');
  } else {
    return fallback;
  }
};

export const getLogo = (company: Companies): string => {
  switch (company.toLocaleLowerCase()) {
    case Companies.SIRMAN:
      return '/image/logo.png';
    case Companies.FELSINEA:
      return '/image/logo_fls.png';
    default:
      return '';
  }
};
