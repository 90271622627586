import { createContext } from 'react';

import { HistoryContext, HistoryState } from './models/interface';

export const initialState: HistoryState = {
  url: undefined,
  page: undefined,
};

export const historyContext = createContext<HistoryContext>({
  state: initialState,
  dispatch: () => {},
});
