import { configureFonts } from '../utils/fonts';
import { between, down, only, up, values } from '../utils/breakpoints';
import { space, unit } from '../utils/units';
import { logoMapper } from '../utils/logo';

export const colors = {
  // Generic
  black: '#000',
  white: '#FFF',
  primary: '#e30613',
  primaryHover: '#a3000b',
  secondary: '#0E7C86',
  background: '#FFF',
  secondaryBackground: '#F7F7F7',
  tertiaryBackground: '#ededed',
  primaryText: '#3F3F46',
  secondaryText: '#3E4C59',
  secondaryTextWhite: '#F6F5F3',
  tertiaryText: '#1F2933',
  forthText: '#616E7C',
  mainTitleText: '#f52a2a',
  paragraphText: '#666666',
  mainTitleTextGold: '#9A7618',
  subtext: '#808080',
  inputPlaceholder: '#9AA5B1',
  borderNeutralButton: '#C2C2C2',
  borderInput: '#CBD2D9',
  borderLink: '#323F4B',
  borderSecondary: '#ebebeb',
  primaryError: '#E12D36',
  divider: 'rgb(0 0 0 / 25%)',
  border: 'rgb(0 0 0 / 25%)',
  disabled: '#00000025',
  readOnly: '#00000025',
  halfOpacityDark: '#00000050',

  // Cards
  overlayColor: '#0d0d0d',

  // Modal
  modalBackground: '#232323',
  reservedAreaModalText: '#333',
  modalTexgtBlackOnWhite: '#333',
  modalSecondaryText: '#777777',

  //Newsletter
  newsletterBackground: '#25231D',
  subTitleText: '#E2DFDA',

  //ProductList
  borderProductListContainer: '#CBD2D9',
  backgroundChecked: '#EBFDFF',

  //Workshop
  mainTitleWorkshopText: '#f52a2a',

  //Dots
  activeDot: '#e30613',
  dot: '#ababab',
  dotHover: '#323F4B',
  secondaryActiveDot: '#fff',
  secondaryDot: '#F7F7F780',
  secondaryDotHover: '#fff',

  //Pagination
  activeNextPrevButton: '#323F4B',
  disabledNextPrevButton: '#ababab',
  secondaryDisabledNextPrevButton: '#F7F7F780',
  secondaryActiveNextPrevButton: '#F7F7F7',

  //Tabs
  dividerTabs: '#E4E7EB',

  //Table
  dividerTable: '#9AA5B1',
  secondaryDividerTable: '#E4E7EB',
  tableCollapseBackground: '#CFF7FC',
  tableEmptyMessage: '#323F4B',
  tableHeaderBackground: '#E4E7EB',
  tableHeaderText: '#616E7C',
  tableIcon: '#616E7C',
  tableNestedHeaderBackground: '#F6F7F9',

  // Shell
  header: '#fff',
  headerText: '#000',
  headerMobileDivider: '#E4E7EB',
  subHeader: '#3E4C59',
  subHeaderText: '#E4E7EB',
  subHeaderTextActive: '#fff',
  bottomNavbarBackground: '#3E4C59',
  bottomNavbarSelected: '#FFFFFF',
  bottomNavbarNotSelected: '#9AA5B1',
  drawerDivider: '#E4E7EB',
  drawerProfileImageBorder: '#2CB1BC',
  drawerCloseButton: '#7B8794',
  drawerProfileImageBackground: '#E4E7EB',
  drawerProfileImageText: '#14919B',
  drawerCaretDown: '#14919B',

  // Autocomplete
  autocompleteBackground: '#FFF',
  autocompleteBorders: '#CBD2D9',
  autocompleteCategoryTitle: '#9AA5B1',
  autocompleteText: '#3E4C59',
  autocompleteTextHover: '#F6F7F9',
  autocompleteItemBackground: '#3E4C59',
  autocompleteItemBackgroundHover: '#E53935',
  autocompleteHintBackground: '#F6F7F9',
  autocompleteHintColor: '#52606D',
  autocompleteNotFoundBackground: '#ffebeb',

  // Buttons
  forthBackgroundColor: '#fff',
  forthBorder: '#3E4C59',
  fifthColor: '#616E7C',
  sixthColor: '#0E7C86',
  sixthBorder: '#8DE4EC',
  ninthBackground: '#EBFDFF',
  ninthColor: '#14919B',
  ninthBorder: '#2CB1BC',
  secondaryBackgroundColorHover: '#FfffFF',
  sixthBackgroundColorHover: '#EBFDFF',
  tertiaryBackgroundColorHover: '#FfffFF',
  tertiaryBackgroundColor: '#FFF',

  // Label
  thirdColor: '#7B8794',

  // BoxForm
  boxFormBorder: '#CBD2D9',
  boxFormLabelColor: '#044E54',
  boxFormLabelBackground: '#EBFDFF',
  boxFormInputRadio: '#2CB1BC',

  // SummaryBox
  summaryBoxBorder: '#CBD2D9',
  summaryBoxSecondaryColor: '#14919B',

  // Returns
  returnBorder: '#CBD2D9',
  returnBorderImageUpload: '#7B8794',
  returnImageUploadIcon: '#14919B',
  returnDeleteBackground: '#9AA5B1',
  returnImagePreviewBorder: '#E4E7EB',

  //Form
  selectIcon: '#3E4C59',

  //ClientPage
  borderClientData: '#CBD2D9',

  //Pagination
  paginationBackgroundCurrent: '#EBFDFF',
  paginationSecondColor: '#14919B',
  paginationDisabledIcon: 'rgba(0, 0, 0, 0.87)',
  paginationSelectLabel: '#52606D',

  //DatePicker
  datePickerIcon: '#2CB1BC',

  //ImagePreview
  zoomInIconBg: '#DDD',
  zoomInIcon: '#666',

  //Megamenu
  borderMegamenu: 'rgb(0 0 0 / 25%)',
  boxShadowMegamenu: 'rgb(0 0 0 / 40%)',
  sectionDivider: '#E4E7EB',

  //Search simple
  searchSimpleImage: '#9AA5B1',

  // Banner
  backgroundColorFallbackBanner: '#e7e7e7',

  // Exception - DONT USE IT
  primaryException: {
    sirman: '#e30613',
    felsinea: '#05788F',
  },
};

const common = {
  // Typology of theme
  dark: false,

  // Colors
  colors: colors,

  // Fonts
  fonts: {
    ...configureFonts(),

    utility: {
      size: (molt = 1): string => `${space * 2 * molt}${unit}`,
      lineHeight: (molt = 1): string => `${space * 4 * molt}${unit}`,
    },

    secondaryButtonSpacing: 'unset',
  },

  // Animations
  animations: {},

  // Custom markdown images heights
  imageHeights: {
    mobile: 230,
    tablet: 500,
  },

  // Custom markdown images widths
  imageWidths: {
    mobile: 350,
    tablet: 696,
  },

  // Spacings
  spacings: {
    unit: (molt = 1): string => `${space * 4 * molt}${unit}`,
  },

  // Borders
  borders: {
    unit: (molt = 1): string => `${space * 1 * molt}${unit}`,
    roundness: `4${unit}`,
    autocomplete: `4${unit}`,
  },

  // Gradients
  gradients: {
    modal: '0px 1px 4px rgba(0, 0, 0, 0.2);',
    autocomplete: '0px 4px 8px 0px rgba(0, 0, 0, 0.16);',
  },

  // Breakpoints
  breakpointsMediaQuery: {
    values,
    up,
    down,
    only,
    between,
  },

  // Theme
  logo: {
    mapper: logoMapper,
  },

  // Tables

  tables: {
    minWidth: '1128px',
    minWidthCell: '200px',
    oddRowBackgroundColor: '#EFEFEF',
    evenRowBackgroundColor: '#F7F7F7',
    rowColor: '#777777',
  },

  // Megamenu

  megamenu: {
    heigthFixer: '200px',
    marginBottom: '100px',
    maxWidth: '1960px',
    boxShadow: '2px 20px 20px rgb(0 0 0 / 20%)',
  },

  // Carousel
  carouselfixedHeightForSmallScreenallScreen: '513px',
};

export default common;
