import '../styles/global.css';

import React, { useCallback, useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { CacheProvider } from '@emotion/react';
import { DefaultSeo } from 'next-seo';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Script from 'next/script';
import {
  getCorrectLangs,
  internationalizationToLanguages,
} from 'corporate-utils';
import { defaultInternationalization, defaultLanguage } from 'corporate-types';

// Utils
import { getStrapiMedia } from '../utils/media';
import createEmotionCache from '../utils/createEmotionCache';
import { canonicalGenerator } from '../utils/seo';
import { Logger } from 'corporate-utils';

// State management
import { SnackbarProvider } from '../stores/snackbar';
import { HistoryProvider } from '../stores/history';

// Theme
import { ThemeProvider } from '../theme';
import { getUseAlternativeCaptcha } from '../utils/captcha';

const clientSideEmotionCache = createEmotionCache();

const MyApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  // Extract the data we need
  const {
    global,
    emotionCache = clientSideEmotionCache,
    hasError,
    languageAlternates,
    metadata,
    slugAnalyzed,
    canonical,
    userContext,
    isPrerenderRequest,
  } = pageProps;

  Logger.info('packages/corporate-ui/src/pages/_app.tsx canonical', canonical);
  const useAlternativeCaptcha = getUseAlternativeCaptcha(userContext?.country);

  const [localGlobalData, setLocalGlobalData] = useState({
    favicon:
      getStrapiMedia(global?.attributes.favicon?.data?.attributes?.url) ||
      process.env.FAVICON ||
      `/image/${process.env.NEXT_PUBLIC_COMPANY}/favicon.ico`,
    metadata: {
      description: global?.metadata?.metaDescription,
      twitter: global?.twitterUsername,
      images:
        global?.metadata?.shareImage?.data?.attributes?.formats &&
        Object.values(
          global?.metadata?.shareImage?.data?.attributes?.formats
        )?.map((image: any) => {
          return {
            url: getStrapiMedia(image?.url),
            width: image?.width,
            height: image?.height,
          };
        }),
    },
  });

  const router = useRouter();

  const resetWindowScrollPosition = useCallback((_, { shallow }) => {
    if (!shallow) {
      const body = document.getElementsByTagName('body')[0];
      body.scrollTo(0, 0);
    }
  }, []);

  const loadWebFont = useCallback(async () => {
    const WebFont = await import('webfontloader');
    WebFont.load({
      custom: {
        families: ['IBM Plex Sans'],
        urls: ['/font.css'],
      },
    });
    WebFont.load({
      custom: {
        families: ['Public Sans'],
        urls: ['/font.css'],
      },
    });
    const html = document.querySelector('html');
    if (html) {
      html.dataset.font = 'true';
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const html = document.querySelector('html');

    if (html?.getAttribute('data-font') == 'true') {
      return;
    }
    loadWebFont();
  }, [loadWebFont]);

  useEffect(() => {
    if (hasError) {
      setLocalGlobalData({
        favicon:
          process.env.FAVICON ||
          `/image/${process.env.NEXT_PUBLIC_COMPANY}/favicon.ico`,
        metadata: {
          description: process.env.META_DESCRIPTION || 'Description',
          twitter: process.env.META_TWITTER || 'Twitter',
          images: undefined,
        },
      });
      router.push('/502');
    }
  }, [hasError, router]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', resetWindowScrollPosition);
    return () => {
      Router.events.off('routeChangeComplete', resetWindowScrollPosition);
    };
  }, [resetWindowScrollPosition]);

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.style.display = 'block';
  }, []);

  const metadataWithDefaults = {
    ...(global?.attributes?.metadata || {}),
    ...metadata,
    locale: router?.locale || defaultInternationalization,
    locales: router?.locales || [defaultInternationalization],
    slugAnalyzed,
    socials: {
      twitter: global?.attributes?.twitterUsername,
      youtube: global?.attributes?.youtubeUsername,
      linkedin: global?.attributes?.linkedinUsername,
      facebook: global?.attributes?.facebookUsername,
    },
  };

  const ogLocaleAlternate = metadataWithDefaults?.locales
    ?.filter((localeAlternate: string) => {
      const LANGS = getCorrectLangs(
        process.env.NEXT_PUBLIC_ENV as string,
        process.env.NEXT_PUBLIC_COMPANY as string
      );

      return !!LANGS.find((lang) => {
        return lang === localeAlternate;
      });
    })
    .map((localeAlternate: string) => {
      return {
        property:
          localeAlternate === metadataWithDefaults?.locale
            ? 'og:locale'
            : 'og:locale:alternate',
        content: localeAlternate,
      };
    });

  const CacheProviderElement = (
    <CacheProvider value={emotionCache}>
      {isPrerenderRequest && (
        <style
          dangerouslySetInnerHTML={{
            __html: ` html {
                  display: block !important;
                }`,
          }}
        ></style>
      )}
      {/* Global site metadata */}
      <DefaultSeo
        additionalLinkTags={[
          {
            rel: 'manifest',
            href: '/manifest.json',
          },
          ...((): any[] => {
            const metatagAlternative = [];
            if (localGlobalData.favicon) {
              metatagAlternative.push({
                rel: 'shortcut icon',
                href: localGlobalData.favicon,
              });
            }
            // Apple favicons
            if (global?.attributes?.metaPWA?.iconIphone) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone180x180) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone180x180.data
                    ?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone167x167) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '167x167',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone167x167.data
                    ?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone152x152) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '152x152',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone152x152.data
                    ?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone144x144) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '144x144',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone144x144.data
                    ?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone120x120) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '120x120',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone120x120.data
                    ?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone114x114) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '114x114',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone114x114.data
                    ?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone76x76) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '76x76',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone76x76.data?.attributes
                    ?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone72x72) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '72x72',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone72x72.data?.attributes
                    ?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone60x60) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '60x60',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone60x60.data?.attributes
                    ?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.iconIphone57x57) {
              metatagAlternative.push({
                rel: 'apple-touch-icon',
                sizes: '57x57',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.iconIphone57x57.data?.attributes
                    ?.url
                ),
              });
            }
            // Chrome-Android favicons
            if (global?.attributes?.metaPWA?.androidChrome192x192) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '192x192',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.androidChrome192x192.data
                    ?.attributes?.url
                ),
              });
            }
            // Favicons
            if (global?.attributes?.metaPWA?.icon196x196) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '196x196',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.icon196x196.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.icon128x128) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '128x128',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.icon128x128.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.icon96x96) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '96x96',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.icon96x96.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.icon64x64) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '64x64',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.icon64x64.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.icon32x32) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.icon32x32.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.icon16x16) {
              metatagAlternative.push({
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.icon16x16.data?.attributes?.url
                ),
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage2048x2732) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage2048x2732.data
                    ?.attributes?.url
                ),
                sizes: '2048x2732',
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage1668x2224) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage1668x2224.data
                    ?.attributes?.url
                ),
                sizes: '1668x2224',
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage1536x2048) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage1536x2048.data
                    ?.attributes?.url
                ),
                sizes: '1536x2048',
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage1125x2436) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage1125x2436.data
                    ?.attributes?.url
                ),
                sizes: '1125x2436',
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage1242x2208) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage1242x2208.data
                    ?.attributes?.url
                ),
                sizes: '1242x2208',
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage750x1334) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage750x1334.data
                    ?.attributes?.url
                ),
                sizes: '750x1334',
              });
            }
            if (global?.attributes?.metaPWA?.appleStartupImage640x1136) {
              metatagAlternative.push({
                rel: 'apple-touch-startup-image',
                href: getStrapiMedia(
                  global?.attributes?.metaPWA?.appleStartupImage640x1136.data
                    ?.attributes?.url
                ),
                sizes: '640x1136',
              });
            }
            return metatagAlternative;
          })(),
        ]}
        additionalMetaTags={[
          {
            name: 'author',
            content: process?.env?.NEXT_PUBLIC_COMPANY_NAME,
          },
          {
            httpEquiv: 'x-dns-prefetch-control',
            content: 'on',
          },
          {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
          },
          {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'default',
          },
          {
            name: 'format-detection',
            content: 'telephone=no',
          },
          {
            name: 'mobile-web-app-capable',
            content: 'yes',
          },
          {
            name: 'msapplication-config',
            content: '/browserconfig.xml',
          },
          {
            name: 'msapplication-tap-highlight',
            content: 'no',
          },
          {
            name: 'viewport',
            content:
              'viewport-fit=cover, width=device-width, initial-scale=1, maximum-scale=5',
          },
          {
            name: 'google',
            content: 'notranslate',
          },
          {
            httpEquiv: 'content-type',
            content: 'text/html; charset=utf-8',
          },
          {
            name: 'keywords',
            content:
              pageProps?.metadata?.keywords ||
              global?.attributes?.metadata?.keywords,
          },
          ...((): any[] => {
            const metatagAlternative = [];
            if (global?.attributes?.metaPWA?.applicationName) {
              metatagAlternative.push({
                name: 'application-name',
                content: global?.attributes?.metaPWA?.applicationName,
              });
            }
            if (global?.attributes?.metaPWA?.appleMobileWebAppTitle) {
              metatagAlternative.push({
                name: 'apple-mobile-web-app-title',
                content: global?.attributes?.metaPWA?.appleMobileWebAppTitle,
              });
            }
            if (global?.attributes?.metaPWA?.msapplicationTileColor) {
              metatagAlternative.push({
                name: 'msapplication-TileColor',
                content: global?.attributes?.metaPWA?.msapplicationTileColor,
              });
            }
            if (global?.attributes?.metaPWA?.themeColor) {
              metatagAlternative.push({
                name: 'theme-color',
                content: global?.attributes?.metaPWA?.themeColor,
              });
            }
            if (ogLocaleAlternate) {
              ogLocaleAlternate?.forEach?.((element: any) => {
                metatagAlternative?.push?.(element);
              });
            }
            return metatagAlternative;
          })(),
        ]}
        canonical={canonicalGenerator(
          router.locale || defaultInternationalization,
          canonical || router.asPath,
          router.query
        )}
        languageAlternates={languageAlternates}
        title={localGlobalData?.metadata?.description}
        description={localGlobalData?.metadata?.description}
        openGraph={{
          images: localGlobalData?.metadata?.images,
        }}
        twitter={{
          cardType: 'summary_large_image',
          site: `${process.env.NEXT_PUBLIC_URL}${router.asPath.split('?')[0]}`,
          handle: localGlobalData?.metadata?.twitter,
        }}
        facebook={{
          appId: global?.attributes?.fbAdmins,
        }}
      />
      <ThemeProvider>
        <SnackbarProvider>
          <HistoryProvider>
            <Component {...pageProps} key={router.asPath} />
          </HistoryProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  );

  return (
    <>
      {process.env.NEXT_PUBLIC_ENV !== 'LOCAL' && (
        <>
          {!isPrerenderRequest && (
            <Script
              data-cbid={process.env.NEXT_PUBLIC_COOKIEBOT_DOMAIN_GROUP_ID}
              data-culture={
                router?.locale !== 'default'
                  ? internationalizationToLanguages(
                    router?.locale || defaultInternationalization,
                    router?.asPath
                  ) || defaultLanguage
                  : defaultLanguage
              }
              data-blockingmode="auto"
              id="CookieBot"
              src="https://consent.cookiebot.com/uc.js"
              data-framework="TCFv2.2"
              type="text/javascript"
              strategy="beforeInteractive"
              onError={(e) => {
                Logger.error('Script failed to load', e);
              }}
            />
          )}
          <Script
            id="gtag-base"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${
        process.env.NEXT_PUBLIC_GTM_ID ?? ''
        }');
        `,
            }}
          />
        </>
      )}

      {!useAlternativeCaptcha ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY || ''}
        >
          {CacheProviderElement}
        </GoogleReCaptchaProvider>
      ) : (
        CacheProviderElement
      )}
    </>
  );
};

export default MyApp;
