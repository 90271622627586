import { unit } from './units';

/* 
xxs, extra-extra-small
ssm, super-small
sm, small
md, medium
lg, large
xl, extra-large

value         |0px     350px    600px    900px    1200px   1920px   9999999px
key           |xs      ssm      sm       md       lg       xl       max
screen width  |-------|--------|--------|--------|--------|--------|-------->
range         |   xs  |   ssm  |   sm   |   md   |   lg   |   xl   |  max

*/

// Variables

export const keys: Array<string> = [
  'xs',
  'ssm',
  's',
  'sTextNews',
  'sm',
  'md',
  'lg',
  'xl',
  'max',
];
export const values: { [key: string]: number } = {
  xs: 0,
  ssm: 350,
  s: 480, // currently used only for ProductState component
  sTextNews: 680,
  sm: 600,
  md: 900,
  lg: 1200,
  lgl: 1536,
  xl: 1920,
  max: 9999999,
};
export const step = 5;

// Functions

export const up = (key: string): string => {
  const value = typeof values[key] === 'number' ? values[key] : key;
  return `@media (min-width:${value}${unit})`;
};

export const down = (key: string): string => {
  const endIndex = keys.indexOf(key);
  const upperbound = values[keys[endIndex]];

  if (endIndex === keys.length) {
    // xl down applies to all sizes
    return up('xxs');
  }

  const value = typeof upperbound === 'number' && endIndex > 0 ? upperbound : 0;
  return `@media (max-width:${value - step / 100}${unit})`;
};

export const between = (start: string, end: string): string => {
  const endIndex = keys.indexOf(end);

  if (endIndex === keys.length - 1) {
    return up(start);
  }

  return (
    `@media (min-width:${
      typeof values[start] === 'number' ? values[start] : start
    }${unit}) and ` +
    `(max-width:${
      (endIndex !== -1 && typeof values[keys[endIndex + 1]] === 'number'
        ? values[keys[endIndex + 1]]
        : 0) -
      step / 100
    }${unit})`
  );
};

export const only = (key: string): string => {
  return between(key, key);
};
